import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";

import login from "./login/index";
import Sidebar from "@/store/misc/sidebar";

import ManageCountries from "@/store/manageCountries/index";
import ManageStates from "@/store/manageStates/index";
import ManageCities from "@/store/manageCities/index";
import ManageAreas from "@/store/manageAreas/index";
import manageBuildings from "@/store/manageBuildings/index";

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        loaderQueue: []
    },
    mutations: {
        setIsLoading(state, isLoading) {
            if (isLoading) {
                state.loaderQueue.push(state.loaderQueue.length + 1);
            } else {
                state.loaderQueue.pop();
            }
        }
    },
    getters: {
        getIsLoading(state) {
            if (state.loaderQueue.length > 0) {
                return true;
            }
            return false;
        }
    },
    actions: {
        async uploadImage({ commit }, imageFile) {
            try {
                const form = new FormData();
                form.append("file", imageFile);

                const request = await axios.post(`${this._vm.$imageAPI}`, form);

                return request.data;
            } catch (error) {
                this._vm.$reportError(error, "Upload Image");
                throw error;
            }
        },
        async uploadWatermarkImage({ commit }, imageFile) {
            try {
                const form = new FormData();
                form.append("file", imageFile);
                form.append("watermark", true);

                const request = await axios.post(`${this._vm.$imageAPI}`, form);
                return request.data;
            } catch (error) {
                this._vm.$reportError(error, "Upload Watermark Image");
                throw error;
            }
        }
    },
    modules: {
        login: login,
        sidebar: Sidebar,

        manageCountries: ManageCountries,
        manageStates: ManageStates,
        manageCities: ManageCities,
        manageAreas: ManageAreas,
        manageBuildings: manageBuildings
    }
});
